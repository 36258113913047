.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -20px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 20px;
  /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column>div {
  margin-bottom: 20px;
}

.thing {
  margin-bottom: 25px;
  transition: all 0.2s ease-in 0.1s;
}

.thing img {
  width: 100%;
  background: #f3f3f3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: all 0.1s ease-in;
}

.thing input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.thing label {
  position: relative;
  display: block;
}

.thing label {
  cursor: pointer;
}

.info {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.lazyloaded~.info {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

@media only screen and (max-width: 700px) {
  .thing {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .my-masonry-grid_column>div {
    margin-bottom: 5px;
  }

  .thing label {
    padding-bottom: 10px;
  }
}

.thing span {
  display: block;
  width: 100%;
  padding-right: 20px;
}

.thing span.note {
  opacity: 0.3;
}

.thing svg {
  fill: #AAF0A4;
  z-index: 50;
  width: 25px;
  height: 25px;
}

.thing:hover img {
  transition: all 0.1s ease-out 0.1s;
}

.thing img.preview {}

.thing img.second {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 0.1s ease-out 0.1s;
}

@media only screen and (min-width: 700px) {
  .thing:hover img.second {
    opacity: 1;
    transform: scale(1.0);
    transition: all 0.1s ease-out 0.1s;
  }

  input[type="checkbox"]:checked~img {
    transform: scale(1.0);
  }
}

input[type="checkbox"]:checked~img.second {
  opacity: 1;
}

input[type="checkbox"]~svg {
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 1px;
  opacity: 0;
}


input[type="checkbox"]:checked~svg {
  opacity: 1;

}

input[type="checkbox"]:checked~img {
  transition: all 0.1s ease-in;
}

.selected {
  background: #AAF0A4;
  outline: 5px #AAF0A4 solid;
}

.unavailable {
  text-decoration-line: line-through;
}

.unavailable svg {
  display: none;
}

input[type="checkbox"]:focus~svg {
  opacity: 0;
}

input[type="checkbox"]:disabled~span {
  color: #999;
}

@media only screen and (max-width: 1100px) {

  .my-masonry-grid {
    margin-left: -15px;
  }

  .my-masonry-grid_column {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .my-masonry-grid {
    margin-left: -15px;
    margin-right: -10px
  }

  .thing label span {
    margin-left: 10px;
  }

  .my-masonry-grid_column {
    padding-left: 5px;
  }
}


.lazyload,
.lazyloading {
  opacity: 0;
  transform: translateY(10px) scale(0.98);
}

.lazyloaded {
  opacity: 1;
  transform: translateY(0px) scale(1);
  transition:
    all 300ms ease-out 100ms,
    transform 300ms ease-out 100ms;
}