#form {
  margin-top: 100px;
}

.text-input,
.text-area {
  display: block;
  width: 100%;
  letter-spacing: -2px;
  border: none;
  border-radius: 0;
  border-bottom: 4px solid hsl(0, 0%, 80%);
  margin-bottom: 25px;
  padding: 0 15px;
  box-sizing: border-box;
}

.text-area {
  padding-top: 15px;
}


.text-input::placeholder {
  padding-top: 12px;
  color: hsl(0, 0%, 80%);
}

.text-area::placeholder {
  color: hsl(0, 0%, 80%);
}

.text-area:not(:placeholder-shown),
.text-input:not(:placeholder-shown) {
  border-color: black;
}

.text-area:not(:disabled):hover,
.text-input:not(:disabled):hover {
  border-color: hsl(0, 100%, 88%);
}

.text-area:focus,
.text-input:focus {
  border-color: hsl(0, 100%, 88%);
  background: hsl(0, 100%, 97%);
  outline: none;
}

.text-area:disabled,
.text-input:disabled {
  border-color: hsl(0, 0%, 80%);
  background: none;
  color: black;
}

.submit-button {
  width: 100%;
  height: 200px;
  font-size: 90px;
  line-height: 90px;
  letter-spacing: -2px;
  background: hsl(0, 100%, 90%);
  border: none;
  cursor: pointer;
}

.submit-button:disabled {
  background: hsl(0, 0%, 97%);
  color: hsl(0, 0%, 80%);
  cursor: not-allowed;
}

.submit-button:not(:disabled):hover {
  background: hsl(0, 100%, 85%);
}

.submit-button:not(:disabled):active {
  transform: scale(0.98);
}


@media only screen and (max-width: 1150px) {

  .text-input,
  .text-area,
  .submit-button {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.4px;
  }

  .submit-button {
    height: 120px;
  }

  .text-input,
  .text-area {
    margin-left: 0;
    border-bottom-width: 3px;
    padding: 5px;
    width: 100%;
  }

  .text-input::placeholder {
    padding-top: 3px;
  }
}

@media only screen and (max-width: 700px) {

  .text-input,
  .text-area,
  .submit-button {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }

  .submit-button {
    height: 60px;
  }

  .text-input,
  .text-area {
    border-bottom-width: 2px;
  }
}


/* Seletec */

.selected-list {
  background: #AAF0A4;
  min-width: 300px;
  margin-bottom: 50px;
}

.selected-list ul {
  padding: 10px 15px;
}

.selected-list ul li {
  display: inline-block;
  margin: 10px 15px;
}

@media only screen and (max-width: 700px) {
  .selected-list ul li {
    display: block;
    margin: 10px 5px;
  }
}