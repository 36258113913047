body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 25px;
  font-family: "Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pale-red {
  color: hsl(0, 100%, 80%);
}

.pale-green {
  color: hsl(115, 100%, 80%);
}

.pale-yellow {
  color: hsl(54, 98%, 62%);
}

#root {
  overflow-x: hidden;
}

.container {
  position: relative;
  max-width: 1440px;
  margin: 40px auto;
  padding: 0 50px;
}

h1,
h2,
.headline {

  font-size: 90px;
  line-height: 90px;
  letter-spacing: -3px;
}

h1,
h2 {
  -webkit-margin-before: 0px;
  -webkit-margin: 0;
  margin: 25px 0 50x 0;
  z-index: 10;
}

h1 {
  font-weight: 600;
  max-width: 1100px;
}

h2 {
  font-weight: 400;
}

ul,
ol {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

section {
  z-index: -10;
}

a[href] {
  color: black;
}

a[href]:hover {
  color: hsl(0, 100%, 80%);
  text-decoration: none;
}

@media only screen and (max-width: 1150px) {

  h1,
  h2,
  .headline {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.4px;
  }

  h1,
  h2 {
    margin: 25px 0;
  }

  h1 {
    max-width: 700px;
  }

  .container {

    margin: 30px 20px;
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  body {
    font-size: 16px;
    line-height: 23px;
  }

  h1,
  h2,
  .headline {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }

  .container {
    margin: 25px 15px;
  }
}