body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 25px;
  font-family: "Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pale-red {
  color: hsl(0, 100%, 80%);
}

.pale-green {
  color: hsl(115, 100%, 80%);
}

.pale-yellow {
  color: hsl(54, 98%, 62%);
}

#root {
  overflow-x: hidden;
}

.container {
  position: relative;
  max-width: 1440px;
  margin: 40px auto;
  padding: 0 50px;
}

h1,
h2,
.headline {

  font-size: 90px;
  line-height: 90px;
  letter-spacing: -3px;
}

h1,
h2 {
  -webkit-margin-before: 0px;
  -webkit-margin: 0;
  margin: 25px 0 50x 0;
  z-index: 10;
}

h1 {
  font-weight: 600;
  max-width: 1100px;
}

h2 {
  font-weight: 400;
}

ul,
ol {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

section {
  z-index: -10;
}

a[href] {
  color: black;
}

a[href]:hover {
  color: hsl(0, 100%, 80%);
  text-decoration: none;
}

@media only screen and (max-width: 1150px) {

  h1,
  h2,
  .headline {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.4px;
  }

  h1,
  h2 {
    margin: 25px 0;
  }

  h1 {
    max-width: 700px;
  }

  .container {

    margin: 30px 20px;
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  body {
    font-size: 16px;
    line-height: 23px;
  }

  h1,
  h2,
  .headline {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }

  .container {
    margin: 25px 15px;
  }
}

.my-masonry-grid {
  display: -webkit-flex;
  display: flex;
  margin-left: -20px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 20px;
  /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column>div {
  margin-bottom: 20px;
}

.thing {
  margin-bottom: 25px;
  transition: all 0.2s ease-in 0.1s;
}

.thing img {
  width: 100%;
  background: #f3f3f3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: all 0.1s ease-in;
}

.thing input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.thing label {
  position: relative;
  display: block;
}

.thing label {
  cursor: pointer;
}

.info {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.lazyloaded~.info {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

@media only screen and (max-width: 700px) {
  .thing {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .my-masonry-grid_column>div {
    margin-bottom: 5px;
  }

  .thing label {
    padding-bottom: 10px;
  }
}

.thing span {
  display: block;
  width: 100%;
  padding-right: 20px;
}

.thing span.note {
  opacity: 0.3;
}

.thing svg {
  fill: #AAF0A4;
  z-index: 50;
  width: 25px;
  height: 25px;
}

.thing:hover img {
  transition: all 0.1s ease-out 0.1s;
}

.thing img.preview {}

.thing img.second {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 0.1s ease-out 0.1s;
}

@media only screen and (min-width: 700px) {
  .thing:hover img.second {
    opacity: 1;
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    transition: all 0.1s ease-out 0.1s;
  }

  input[type="checkbox"]:checked~img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

input[type="checkbox"]:checked~img.second {
  opacity: 1;
}

input[type="checkbox"]~svg {
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 1px;
  opacity: 0;
}


input[type="checkbox"]:checked~svg {
  opacity: 1;

}

input[type="checkbox"]:checked~img {
  transition: all 0.1s ease-in;
}

.selected {
  background: #AAF0A4;
  outline: 5px #AAF0A4 solid;
}

.unavailable {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.unavailable svg {
  display: none;
}

input[type="checkbox"]:focus~svg {
  opacity: 0;
}

input[type="checkbox"]:disabled~span {
  color: #999;
}

@media only screen and (max-width: 1100px) {

  .my-masonry-grid {
    margin-left: -15px;
  }

  .my-masonry-grid_column {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .my-masonry-grid {
    margin-left: -15px;
    margin-right: -10px
  }

  .thing label span {
    margin-left: 10px;
  }

  .my-masonry-grid_column {
    padding-left: 5px;
  }
}


.lazyload,
.lazyloading {
  opacity: 0;
  -webkit-transform: translateY(10px) scale(0.98);
          transform: translateY(10px) scale(0.98);
}

.lazyloaded {
  opacity: 1;
  -webkit-transform: translateY(0px) scale(1);
          transform: translateY(0px) scale(1);
  transition:
    all 300ms ease-out 100ms,
    -webkit-transform 300ms ease-out 100ms;
  transition:
    all 300ms ease-out 100ms,
    transform 300ms ease-out 100ms;
  transition:
    all 300ms ease-out 100ms,
    transform 300ms ease-out 100ms,
    -webkit-transform 300ms ease-out 100ms;
}
section#cover {
  position: relative;
  margin-bottom: 150px;
}

video {
  position: absolute;
  top: 0;
  z-index: -100;
  width: 100%;
  opacity: 0.1;
}

#cover svg {
  z-index: -100;
}

.shape {
  transition: all 1s ease-out;
  z-index: -100;
}

@media only screen and (max-width: 700px) {
  .shape svg {
    transition: all 1s ease-out;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    z-index: -100;
  }
}

.rombus_1 svg {
  position: absolute;
  top: 5px;
  left: 50px;
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.circlus_1 svg {
  position: absolute;
  bottom: -505px;
  right: 50px;
  -webkit-animation-name: shape_ani_1;
  animation-name: shape_ani_1;
  -webkit-animation-duration: 23s;
  animation-duration: 23s;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.starus_1 svg {
  position: absolute;
  bottom: -5px;
  left: 0;
  -webkit-animation-name: shape_ani_2;
  animation-name: shape_ani_2;
  -webkit-animation-duration: 43s;
  animation-duration: 43s;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}


.rombus_2 svg {
  position: absolute;
  top: -15px;
  left: 0px;
  -webkit-animation-name: shape_ani;
  animation-name: shape_ani;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.circlus_2 svg {
  position: absolute;
  bottom: -150px;
  right: -150px;
  -webkit-animation-name: shape_ani;
  animation-name: shape_ani;
  -webkit-animation-duration: 23s;
  animation-duration: 23s;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.starus_2 svg {
  position: absolute;
  bottom: -20vw;
  left: 30vw;
  -webkit-animation-name: shape_ani;
  animation-name: shape_ani;
  -webkit-animation-duration: 43s;
  animation-duration: 43s;
  -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

@-webkit-keyframes shape_ani {
  0% {
    -webkittransform: rotate(0);
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  30% {
    -webkit-transform: rotate(200deg) scale(1.1);
            transform: rotate(200deg) scale(1.1);
  }

  to {
    -webkittransform: rotate(720);
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
  }
}

@keyframes shape_ani {
  0% {
    -webkittransform: rotate(0);
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  30% {
    -webkit-transform: rotate(200deg) scale(1.1);
            transform: rotate(200deg) scale(1.1);
  }

  to {
    -webkittransform: rotate(720);
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
  }
}

@-webkit-keyframes shape_ani_1 {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  20% {
    -webkit-transform: rotate(200deg) translate(200px, 180px) scale(1.1);
            transform: rotate(200deg) translate(200px, 180px) scale(1.1);
  }

  60% {
    -webkit-transform: rotate(420deg) translate(-200px, -50px) scale(.7);
            transform: rotate(420deg) translate(-200px, -50px) scale(.7);
  }

  to {
    -webkit-transform: scaleX(1) rotate(720deg) translate(0, 0) scale(1);
            transform: scaleX(1) rotate(720deg) translate(0, 0) scale(1);
  }
}

@keyframes shape_ani_1 {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  20% {
    -webkit-transform: rotate(200deg) translate(200px, 180px) scale(1.1);
            transform: rotate(200deg) translate(200px, 180px) scale(1.1);
  }

  60% {
    -webkit-transform: rotate(420deg) translate(-200px, -50px) scale(.7);
            transform: rotate(420deg) translate(-200px, -50px) scale(.7);
  }

  to {
    -webkit-transform: scaleX(1) rotate(720deg) translate(0, 0) scale(1);
            transform: scaleX(1) rotate(720deg) translate(0, 0) scale(1);
  }
}

@-webkit-keyframes shape_ani_2 {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  30% {
    -webkit-transform: rotate(-200deg) translate(200px, -70px) scale(.6);
            transform: rotate(-200deg) translate(200px, -70px) scale(.6);
  }

  70% {
    -webkit-transform: rotate(220deg) translate(100px, 70px) scale(1);
            transform: rotate(220deg) translate(100px, 70px) scale(1);
  }

  to {
    -webkit-transform: scaleX(1) rotate(520deg) translate(0, 0) scale(1);
            transform: scaleX(1) rotate(520deg) translate(0, 0) scale(1);
  }
}

@keyframes shape_ani_2 {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  30% {
    -webkit-transform: rotate(-200deg) translate(200px, -70px) scale(.6);
            transform: rotate(-200deg) translate(200px, -70px) scale(.6);
  }

  70% {
    -webkit-transform: rotate(220deg) translate(100px, 70px) scale(1);
            transform: rotate(220deg) translate(100px, 70px) scale(1);
  }

  to {
    -webkit-transform: scaleX(1) rotate(520deg) translate(0, 0) scale(1);
            transform: scaleX(1) rotate(520deg) translate(0, 0) scale(1);
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-300deg) translateY(10vw);
    transform: scale3d(.9, .9, .9) rotate(-300deg) translateY(10vw);
  }

  50%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(300deg) skewX(30deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(300deg) skewX(30deg);
  }

  40%,
  80% {
    -webkit-transform: translateX(20vw) rotate(-3deg);
    transform: translateX(20vw) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1) translate(0, 0);
    transform: scaleX(1) translate(0, 0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-300deg) translateY(10vw);
    transform: scale3d(.9, .9, .9) rotate(-300deg) translateY(10vw);
  }

  50%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(300deg) skewX(30deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(300deg) skewX(30deg);
  }

  40%,
  80% {
    -webkit-transform: translateX(20vw) rotate(-3deg);
    transform: translateX(20vw) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1) translate(0, 0);
    transform: scaleX(1) translate(0, 0);
  }
}
#faq {
  margin-top: 50px;
}

#faq ul {}

#faq li {
  list-style-type: none;
  margin-bottom: 50px;
}

#faq h4 {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 700px) {
  #faq li {
    list-style-type: none;
    margin-bottom: 25px;
  }
}
#form {
  margin-top: 100px;
}

.text-input,
.text-area {
  display: block;
  width: 100%;
  letter-spacing: -2px;
  border: none;
  border-radius: 0;
  border-bottom: 4px solid hsl(0, 0%, 80%);
  margin-bottom: 25px;
  padding: 0 15px;
  box-sizing: border-box;
}

.text-area {
  padding-top: 15px;
}


.text-input::-webkit-input-placeholder {
  padding-top: 12px;
  color: hsl(0, 0%, 80%);
}


.text-input::-ms-input-placeholder {
  padding-top: 12px;
  color: hsl(0, 0%, 80%);
}


.text-input::placeholder {
  padding-top: 12px;
  color: hsl(0, 0%, 80%);
}

.text-area::-webkit-input-placeholder {
  color: hsl(0, 0%, 80%);
}

.text-area::-ms-input-placeholder {
  color: hsl(0, 0%, 80%);
}

.text-area::placeholder {
  color: hsl(0, 0%, 80%);
}

.text-area:not(:placeholder-shown),
.text-input:not(:placeholder-shown) {
  border-color: black;
}

.text-area:not(:disabled):hover,
.text-input:not(:disabled):hover {
  border-color: hsl(0, 100%, 88%);
}

.text-area:focus,
.text-input:focus {
  border-color: hsl(0, 100%, 88%);
  background: hsl(0, 100%, 97%);
  outline: none;
}

.text-area:disabled,
.text-input:disabled {
  border-color: hsl(0, 0%, 80%);
  background: none;
  color: black;
}

.submit-button {
  width: 100%;
  height: 200px;
  font-size: 90px;
  line-height: 90px;
  letter-spacing: -2px;
  background: hsl(0, 100%, 90%);
  border: none;
  cursor: pointer;
}

.submit-button:disabled {
  background: hsl(0, 0%, 97%);
  color: hsl(0, 0%, 80%);
  cursor: not-allowed;
}

.submit-button:not(:disabled):hover {
  background: hsl(0, 100%, 85%);
}

.submit-button:not(:disabled):active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}


@media only screen and (max-width: 1150px) {

  .text-input,
  .text-area,
  .submit-button {
    font-size: 60px;
    line-height: 60px;
    letter-spacing: -0.4px;
  }

  .submit-button {
    height: 120px;
  }

  .text-input,
  .text-area {
    margin-left: 0;
    border-bottom-width: 3px;
    padding: 5px;
    width: 100%;
  }

  .text-input::-webkit-input-placeholder {
    padding-top: 3px;
  }

  .text-input::-ms-input-placeholder {
    padding-top: 3px;
  }

  .text-input::placeholder {
    padding-top: 3px;
  }
}

@media only screen and (max-width: 700px) {

  .text-input,
  .text-area,
  .submit-button {
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.4px;
  }

  .submit-button {
    height: 60px;
  }

  .text-input,
  .text-area {
    border-bottom-width: 2px;
  }
}


/* Seletec */

.selected-list {
  background: #AAF0A4;
  min-width: 300px;
  margin-bottom: 50px;
}

.selected-list ul {
  padding: 10px 15px;
}

.selected-list ul li {
  display: inline-block;
  margin: 10px 15px;
}

@media only screen and (max-width: 700px) {
  .selected-list ul li {
    display: block;
    margin: 10px 5px;
  }
}
