section#cover {
  position: relative;
  margin-bottom: 150px;
}

video {
  position: absolute;
  top: 0;
  z-index: -100;
  width: 100%;
  opacity: 0.1;
}

#cover svg {
  z-index: -100;
}

.shape {
  transition: all 1s ease-out;
  z-index: -100;
}

@media only screen and (max-width: 700px) {
  .shape svg {
    transition: all 1s ease-out;
    transform: scale(0.5);
    z-index: -100;
  }
}

.rombus_1 svg {
  position: absolute;
  top: 5px;
  left: 50px;
  -webkit-animation-name: tada;
  animation-name: tada;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.circlus_1 svg {
  position: absolute;
  bottom: -505px;
  right: 50px;
  -webkit-animation-name: shape_ani_1;
  animation-name: shape_ani_1;
  -webkit-animation-duration: 23s;
  animation-duration: 23s;
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.starus_1 svg {
  position: absolute;
  bottom: -5px;
  left: 0;
  -webkit-animation-name: shape_ani_2;
  animation-name: shape_ani_2;
  -webkit-animation-duration: 43s;
  animation-duration: 43s;
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}


.rombus_2 svg {
  position: absolute;
  top: -15px;
  left: 0px;
  -webkit-animation-name: shape_ani;
  animation-name: shape_ani;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.circlus_2 svg {
  position: absolute;
  bottom: -150px;
  right: -150px;
  -webkit-animation-name: shape_ani;
  animation-name: shape_ani;
  -webkit-animation-duration: 23s;
  animation-duration: 23s;
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

.starus_2 svg {
  position: absolute;
  bottom: -20vw;
  left: 30vw;
  -webkit-animation-name: shape_ani;
  animation-name: shape_ani;
  -webkit-animation-duration: 43s;
  animation-duration: 43s;
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}

@keyframes shape_ani {
  0% {
    -webkittransform: rotate(0);
    transform: rotate(0);
  }

  30% {
    transform: rotate(200deg) scale(1.1);
  }

  to {
    -webkittransform: rotate(720);
    transform: rotate(720deg);
  }
}

@keyframes shape_ani_1 {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  20% {
    transform: rotate(200deg) translate(200px, 180px) scale(1.1);
  }

  60% {
    transform: rotate(420deg) translate(-200px, -50px) scale(.7);
  }

  to {
    transform: scaleX(1) rotate(720deg) translate(0, 0) scale(1);
  }
}

@keyframes shape_ani_2 {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  30% {
    transform: rotate(-200deg) translate(200px, -70px) scale(.6);
  }

  70% {
    transform: rotate(220deg) translate(100px, 70px) scale(1);
  }

  to {
    transform: scaleX(1) rotate(520deg) translate(0, 0) scale(1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  20% {
    -webkit-transform: scale3d(.9, .9, .9) rotate(-300deg) translateY(10vw);
    transform: scale3d(.9, .9, .9) rotate(-300deg) translateY(10vw);
  }

  50%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(300deg) skewX(30deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(300deg) skewX(30deg);
  }

  40%,
  80% {
    -webkit-transform: translateX(20vw) rotate(-3deg);
    transform: translateX(20vw) rotate(-3deg);
  }

  to {
    -webkit-transform: scaleX(1) translate(0, 0);
    transform: scaleX(1) translate(0, 0);
  }
}