#faq {
  margin-top: 50px;
}

#faq ul {}

#faq li {
  list-style-type: none;
  margin-bottom: 50px;
}

#faq h4 {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 700px) {
  #faq li {
    list-style-type: none;
    margin-bottom: 25px;
  }
}